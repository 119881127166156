import { T } from "@/locales";

export default {
  name: [
    { required: true, message: T('请输入订单名称'), trigger: 'change' },
    { max: 64, message: T('超出最大长度 (64)'), trigger: 'change' },
  ],
  type: [
    { required: true, message: T('请选择订单类型'), trigger: 'change' },
  ],
  remark: [{ max: 256, message: T('超出最大长度 (256)'), trigger: 'change' }],
}